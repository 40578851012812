/**
 * @name: 售后管理-补发货记录接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 售后管理-补发货记录接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IAfterSendRecordRes, IAfterSendRecordParam} from "@/apis/after/sendRecord/types";

/**
 * 补发货分页查询
 * @param params
 */
export const querySupplementSendGoodsByPageApi = (params: IAfterSendRecordParam) => get<IAfterSendRecordRes>("/card/after/sales/manage/querySupplementSendGoodsByPage", params)
/**
 * 补发货导出
 * @param params
 */
export const supplementSendGoodsExportApi = (params: IAfterSendRecordParam) => get("/card/data/statistics/supplementSendGoodsExport", params, "blob")


/**
 * @name: 售后管理-补发货记录
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 售后管理-补发货记录
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {querySupplementSendGoodsByPageApi, supplementSendGoodsExportApi} from "@/apis/after/sendRecord"
import { IAfterSendRecord, IAfterSendRecordParam } from "@/apis/after/sendRecord/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class afterSendRecord extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAfterSendRecord[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IAfterSendRecordParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品",
        prop: "productKeywords",
        search: true,
        hide: true,
        placeholder: "请输入商品ID/商品名称"
      },
      {
        label: "订单编号",
        prop: "orderSn",
        width: 180,
        align: "left",
        search: true
      },
      {
        label: "用户",
        prop: "userKeywords",
        search: true,
        hide: true,
        placeholder: "请输入用户名/手机号"
      },
      {
        label: "用户名",
        prop: "userName",
        align: "center",
        overHidden: true
      },
      {
        label: "手机号",
        prop: "userPhone",
        align: "center"
      },
      {
        label: "商品ID",
        prop: "productId",
        align: "center",
        width: 180
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品售价(元)",
        prop: "price",
        align: "center"
      },
      {
        label: "补发数量",
        prop: "supplyAgainQty",
        align: "center"
      },
      {
        label: "商品金额",
        prop: "productPrice",
        align: "center"
      },
      {
        label: "经办人",
        prop: "operator",
        align: "center",
        search: true
      },
      {
        label: "发货时间",
        prop: "deliveryTime",
        align: "center",
        search: true,
        type: "daterange",
        width: 150
      },
    ]
  }

  deliveryQtyTotal = 0
  productPriceTotal = 0

  getList () {
    this.tableLoading = true

    const query: IAfterSendRecordParam = deepCopy(this.queryParam)
    if (query.deliveryTime && query.deliveryTime.length) {
      query.deliveryTimeStart = query.deliveryTime[0]
      query.deliveryTimeEnd = query.deliveryTime[1]
    } else {
      query.deliveryTimeStart = ''
      query.deliveryTimeEnd = ''
    }
    delete query.deliveryTime

    querySupplementSendGoodsByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.commonPage.list
        this.tableTotal = e.commonPage.total

        this.deliveryQtyTotal = e.deliveryQtyTotal || 0
        this.productPriceTotal = e.productPriceTotal || 0

        this.tableLoading = false
      }
    })
  }

  exportExcel () {
    const query: IAfterSendRecordParam = deepCopy(this.queryParam)
    if (query.deliveryTime && query.deliveryTime.length) {
      query.deliveryTimeStart = query.deliveryTime[0]
      query.deliveryTimeEnd = query.deliveryTime[1]
    } else {
      query.deliveryTimeStart = ''
      query.deliveryTimeEnd = ''
    }
    delete query.deliveryTime

    supplementSendGoodsExportApi(query).then(e => {
      exportFile(e, "补发货记录.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
